<template>
  <div class="bg-gray-100">
    <div class="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:flex lg:items-center lg:justify-between lg:px-8 lg:py-16">
      <h2 class="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Find more Pilot Car Jobs now!
        <br>
        <span class="text-teal-700">Start your 30 day free trial</span>
      </h2>
      <div class="mt-8 flex lg:mt-0 lg:shrink-0">
        <div class="inline-flex rounded-md shadow">
          <nuxt-link
            to="/register"
            class="inline-flex items-center justify-center rounded-md border border-transparent bg-teal-700 px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-teal-600 focus:outline-none"
          >
            Join for free
          </nuxt-link>
        </div>
        <div class="ml-3 inline-flex rounded-md shadow">
          <nuxt-link
            to="/why-choose-us"
            class="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-teal-700 hover:bg-teal-50"
          >
            Why choose us
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>
